<template>
    <div v-if="reviews.length == 0">
        <p class="no-reviews-text">{{ t('list_reviews_user.no_reviews') }}</p>
    </div>
    <div 
        v-else 
        :class="listClass"
    >
        <div 
            :key="review.id" 
            v-for="review in reviews"
            class="af-item-wrap"
        >
            <ReviewUser :review="review" />
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useGrid } from 'vue-screen'

import ReviewUser from './ReviewUser.vue'

export default {
    name: 'ListReviewsUser',
    components: {
        ReviewUser
    },
    props: {
        /** Список отзывов о пользователе
         * Должен быть набор Object, содержащих поля:
         * - id
         * - text
         * - adUuid
         * - response
         *    - text
         *    - date
         * - date
         * - username
         * - rating - рейтинг, который пользователь оставил у отзыва
         */
        reviews: {
            type: Array,
            default() {
                return [
                    {
                        id: "0",
                        text: "Example review text",
                        adUuid: "00000000-0000-0000-0000-000000000000",
                        response: {
                            text: "Review response",
                            date: new Date()
                        },
                        date: new Date(),
                        username: "",
                        rating: 3.5
                    }
                ]
            }
        }
    },
    setup() {
        const { t } = useI18n();
        const grid = useGrid('bootstrap')

        return {
            t,
            grid,
        }
    },
    computed: {
        listClass() {
            if (this.grid.lg) {
                return "af-items-2 af-listing-grid"
            }
            
            return "af-items-1 af-listing-list"
        }
    }
}
</script>

<style scoped>
.no-reviews-text {
    text-align: start;
    padding: 30px 5px;
    margin: 10px;
}
</style>
