<template>
    <div class="user-review hover-shadow">
        <div class="flex-wrap flex-center">
            <RatingStar :rating="review.rating" />
        </div>
        
        <div class="review-details">
            <div class="flex-wrap flex-center">
                <p class="profile-small-title">{{ $d(review.date) }}</p>
                <router-link 
                    :to="{ name: 'AdDetails', params: { id: review.adUuid } }" 
                    class="profile-small-title ad-link">
                    {{ t('review_user.view_ad') }}
                </router-link>
            </div>
            <div> 
                <p class="reviewer-name">
                    {{ review.username.length == 0 ? t('default_username') : review.username }} 
                </p>
            </div>
        </div>
        <div class="review-text">
            <p class="no-margin" style="white-space: pre-line;">{{ review.text }}</p>
            <div v-if="review.response.text.length != 0" class="review-response">
                <p class="profile-small-title">{{ t('review_user.author_response') }} | {{ $d(review.response.date) }}</p>
                <br />
                <span style="white-space: pre-line;">
                    {{ review.response.text }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import RatingStar from './RatingStar.vue'

export default {
    name: 'UserReview',
    components: {
        RatingStar
    },
    props: {
        review: {
            type: Object,
            default() {
                return {
                    text: "Example review text. This is not intended to be shown.",
                    adUuid: "00000000-0000-0000-0000-000000000000",
                    response: {
                        text: "",
                        date: new Date()
                    },
                    date: new Date(),
                    username: "",
                    rating: 3.5
                }
            }
        }
    },
    setup() {
      const { t } = useI18n();

      return {
          t,
      }
    }
}
</script>

<style scoped>
.review-details {
    padding-top: 10px;
}

.user-review {
    background: #fff;
    padding: 30px;
    border-radius: 2px;
    margin: 10px 0px;
}

.user-rating {
    font-size: 14px;
}

.review-text {
    padding: 15px 25px;
    background: #fafafa;
    position: relative;
    margin-top: 10px;
    text-align: start;
}

.review-response {
    margin: 15px 0px 10px 15px;
    background: #fff;
    padding: 15px 25px;
}

.profile-small-title {
    display: inline-block;
    text-transform: none;
    font-size: 12px;
    font-weight: 400;
    color: #aaa;
    line-height: 1;
}

.ad-link {
    text-decoration: underline;
}

.reviewer-name {
    text-align: start;
}
</style>