<template>
  <div class="author-visited" style="margin-top: 20px;">
    <SsrHead :meta="computedMeta" />

    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <div class="white-block author-has-posts">
            <div class="white-block-content">
              <UserSmall :user="data.user" />
            </div>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="white-block author-listing" style="margin-bottom: 15px;">
            <div class="white-block-content">
              <div class="flex-wrap">
                <h5 class="no-margin">{{ t("user_details.user_ads") }}</h5>
              </div>
            </div>
          </div>

          <div class="kc_wrapper ui-tabs kc_clearfix">
            <ul class="kc_tabs_nav ui-tabs-nav kc_clearfix">
              <li :class="data.showActiveAds ? 'ui-tabs-active' : ''">
                <button @click="setShowActive(true)">
                  {{ t("user_details.active_ads") }}
                </button>
              </li>
              <li :class="data.showActiveAds ? '' : 'ui-tabs-active'">
                <button @click="setShowActive(false)">
                  {{ t("user_details.sold_ads") }}
                </button>
              </li>
            </ul>
            <GridAds :ads="showingAds" :maxItems="4" />
          </div>
        </div>
      </div>
      <div class="author-reviews" data-author="1">
        <div class="white-block">
          <div class="white-block-content">
            <div class="flex-wrap">
              <h5>{{ t("user_details.user_reviews") }}</h5>
            </div>
          </div>
        </div>
        <div class="author-reviews">
          <ListReviewsUser :reviews="data.reviews" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onServerPrefetch } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";
import { useRoute, useRouter } from "vue-router";

import UserSmall from "../components/UserSmall.vue";
import GridAds from "../components/GridAds.vue";
import ListReviewsUser from "../components/ListReviewsUser.vue";
import SsrHead from "../components/SsrHead.vue";

import {
  getUser as getUserApi,
  UserStatus as UserStatusApi,
  getUserReviews as getUserReviewsApi,
} from "../api/user_details.js";
import { getUserAds as getUserAdsApi } from "../api/ad_details.js";

import DefaultAvatar from "../assets/img/default_avatar.png";

const LoadStatus = {
  Success: "Success",
  Redirect: "Redirect",
  Error: "Error",
};

/**
 * Получить данные, нужные на этой странице
 * @param {string} user_id - id пользователя из параметров запроса
 * @returns {Object} Вернет объект с полями:
 * - status {LoadStatus}
 * - data - данные, нужные для LoadStatus
 */
async function loadData(user_id) {
  var user = {};
  try {
    var get_user = await getUserApi(user_id);

    if (get_user.status == UserStatusApi.Success) {
      user = get_user.user;
    } else if (get_user.status == UserStatusApi.NotFound) {
      return {
        status: LoadStatus.Redirect,
        data: { name: "NotFound" },
      };
    }
  } catch (e) {
    return {
      status: LoadStatus.Error,
      data: e,
    };
  }

  var activeAds = [];
  var soldAds = [];
  try {
    let active = await getUserAdsApi({ user_id, ad_type: "active", size: 12 });
    activeAds = active.ads;

    let sold = await getUserAdsApi({ user_id, ad_type: "sold", size: 12 });
    soldAds = sold.ads;
  } catch (e) {
    return {
      status: LoadStatus.Error,
      data: e,
    };
  }

  var reviews = [];
  try {
    reviews = await getUserReviewsApi(user_id);
  } catch (e) {
    // TODO: показывать что ошибка только с reviews
    return {
      status: LoadStatus.Error,
      data: e,
    };
  }

  return {
    status: LoadStatus.Success,
    data: {
      user,
      activeAds,
      soldAds,
      reviews,
    },
  };
}

export default {
  name: "UserDetails",
  components: {
    UserSmall,
    GridAds,
    ListReviewsUser,
    SsrHead,
  },
  setup() {
    const { t } = useI18n();
    const data = reactive({
      user: {
        thumbUrl: DefaultAvatar,
        id: "00000000-0000-0000-0000-000000000000",
        username: t("default_username"),
        rating: 3.5,
        lastSeen: 0,
      },
      showActiveAds: true,
      activeAds: [],
      soldAds: [],
      reviews: [
        {
          id: "0",
          text: "Example review text",
          adUuid: "00000000-0000-0000-0000-000000000000",
          response: {
            text: "Review response",
            date: new Date(),
          },
          date: new Date(),
          username: "",
          rating: 3.5,
        },
      ],
    });

    const computedMeta = computed(() => {
      var og = {
        url: typeof window !== "undefined" ? window.location.href : "",
        title: t("user_details.profile_title"),
        description: t("user_details.description", {
          username: data.user.username,
        }),
        type: "profile",
      };

      if (data.user.username) {
        og.profile = {};
        og.profile.username = data.user.username;
        return {
          title: t("user_details.title", { username: data.user.username }),
          description: t("user_details.description", {
            username: data.user.username,
          }),
          og,
        };
      }

      return {
        title: t("user_details.title_no_username"),
        description: t("user_details.description", {
          username: "",
        }),
        og,
      };
    });
    useMeta(computedMeta);

    onServerPrefetch(async () => {
      const route = useRoute();
      const router = useRouter();

      var res = await loadData(route.params.id);

      if (res.status == LoadStatus.Success) {
        data.user = res.data.user;
        data.activeAds = res.data.activeAds;
        data.soldAds = res.data.soldAds;
        data.reviews = res.data.reviews;
      } else if (res.status == LoadStatus.Redirect) {
        router.push(res.data);
      } else if (res.status == LoadStatus.Error) {
        router.push({ name: "RouterError" });
      }
    });

    return {
      t,
      data,
      computedMeta,
    };
  },
  async beforeRouteEnter(to, from, next) {
    var res = await loadData(to.params.id);

    if (res.status == LoadStatus.Success) {
      next((vm) =>
        vm.setData({
          user: res.data.user,
          activeAds: res.data.activeAds,
          soldAds: res.data.soldAds,
          reviews: res.data.reviews,
        })
      );
    } else if (res.status == LoadStatus.Redirect) {
      next(res.data);
    } else if (res.status == LoadStatus.Error) {
      throw ["Error loading user data", res];
    }
  },
  async beforeRouteUpdate(to) {
    var data = await loadData(to.params.id);

    if (data.status == LoadStatus.Success) {
      this.setData({
        user: data.data.user,
        activeAds: data.data.activeAds,
        soldAds: data.data.soldAds,
        reviews: data.data.reviews,
      });
    } else if (data.status == LoadStatus.Redirect) {
      return data.data;
    } else if (data.status == LoadStatus.Error) {
      console.error("Error loading profile data:", data);
    }
  },
  methods: {
    setData({ user, activeAds, soldAds, reviews }) {
      this.data.user = user;
      this.data.activeAds = activeAds;
      this.data.soldAds = soldAds;
      this.data.reviews = reviews;
    },
    setShowActive(show) {
      this.data.showActiveAds = show;
    },
  },
  computed: {
    showingAds() {
      if (this.data.showActiveAds) {
        return this.data.activeAds;
      } else {
        return this.data.soldAds;
      }
    },
  },
};
</script>

<style scoped>
button {
  border: none;
}

.kc_tabs_nav > li {
  margin: 3px;
}

.kc_tabs_nav > li > button {
  padding: 3px 15px;
}
</style>
